function notificationKey(idx) {
  return `notification-${idx}`;
}

class Component {
  onCreate() {
    this.state = {
      notifications: [],
      notificationsState: new Map(),
    };
  }

  removeNotification(idx) {
    const { notifications, notificationsState } = this.state;

    notifications.splice(idx, 1);
    notificationsState.delete(notificationKey(idx));

    this.setStateDirty('notifications');
  }

  closeNotification(idx) {
    const { notificationsState } = this.state;

    notificationsState.set(
      notificationKey(idx),
      new Map([['direction', 'out']])
    );

    this.setStateDirty('notificationsState');
  }

  onAnimationEnd(event) {
    const { dataset } = event.target;

    if (dataset.direction === 'out') {
      this.removeNotification(dataset.notificationIdx);
    }
  }

  onNotify(data, level) {
    const {
      level: dataLevel,
      body,
      description,
      message,
      body_html,
      description_html,
      message_html,
      title,
    } = data;
    this.state.notifications.unshift({
      level: dataLevel || level,
      body: body || description || message,
      body_html: body_html || description_html || message_html,
      title,
    });
    this.setStateDirty('notifications');
  }

  onNotificationsClick(event) {
    const { target } = event;
    const { nodeName, dataset } = target;

    if (nodeName.toLowerCase() === 'button' && dataset.notificationIdx) {
      this.closeNotification(dataset.notificationIdx);
    }
  }

  // Public methods.
  notify(data, level = 'success') {
    this.onNotify(data, level);
  }

  error(data) {
    this.state.notifications.unshift({
      level: 'danger',
      error: data,
    });
    this.setStateDirty('notifications');
  }

  onError(error) {
    this.error(error);
  }
}

export default Component;
