$ const { notifications, notificationsState } = state;
<if(notifications && notifications.length)>
  <div class:no-update="notifications" on-click("onNotificationsClick")>
    <for|notification, idx| of=notifications>
      $ {
        const { body, body_html, title, level, error } = notification;

        const notificationKey = `notification-${idx}`;
        const notificationState = notificationsState.get(notificationKey);
        const direction = notificationState?.get('direction') ?? 'in';
        const animation = direction === 'in' ? 'animate__fadeIn' : 'animate__fadeOut';
      }
      <if(error)>
        $ {
          const { error_description_html , description_html , message_html, error_description, description, message } = error;
          const errHtmlDescription = error_description_html || description_html || message_html;
          const errDescription = error_description || description || message;
        }
        <div no-update-body key=notificationKey class=["app-notification animate__animated", animation] data-notification-idx=idx data-direction=direction on-animationend("onAnimationEnd")>
          <button class="delete" aria-label="Close notification" data-notification-idx=idx></button>
          <div class="container">
            <if(errHtmlDescription)>
              $!{errHtmlDescription}
            </if>
            <else-if(errDescription)>
              ${errDescription}
            </else-if>
            <else>
              Cannot process your request. Try again later.
            </else>
          </div>
        </div>
      </if>
      <if(body || body_html)>
        <div no-update-body key=notificationKey class=["app-notification animate__animated", animation] data-notification-idx=idx data-direction=direction on-animationend("onAnimationEnd")>
          <button class="delete" aria-label="Close notification" data-notification-idx=idx></button>
          <div class="container">
            <if(body_html)>
              $!{body_html}
            </if>
            <else-if(body)>
              ${body}
            </else-if>
          </div>
        </div>
      </if>
    </for>
  </div>
</if>
