import requestProxy from '../../lib/request-proxy.mjs';
import { bufferToString, stringToBuffer } from '../../utils/buffer.mjs';

class Component {
  onCreate(input) {
    const { error, partner, loginAuthHref } = input;
    this.state = {
      error,
      partner,
      loginAuthHref,
      isLoading: false,
    };
    this.notification = null;
  }

  onMount() {
    this.notification = this.getComponent('notification');
    this.form = this.getEl('form');

    this.notifyError();
  }

  notifyError() {
    const { error } = this.state;
    if (error) {
      this.notification.onError(error);
    }
  }

  reset() {
    this.setState('isLoading', false);
    this.form.reset();
  }

  async handleLogin(data) {
    let location;

    const { loginAuthHref } = this.state;

    location = '/factories';

    try {
      const proxy = requestProxy();
      const response = await proxy.post({ path: loginAuthHref, data });

      if (response.redirected) {
        location = response.headers.get('location') || '/factories';
      }

      window.location.assign(location);
    } catch (ex) {
      this.notification.onError(ex);
      this.reset();
    }
  }

  async onLoginFormSubmit(event, form) {
    event.preventDefault();
    event.stopPropagation();

    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }

    const data = new window.FormData(form);

    setTimeout(() => {
      this.setState('isLoading', true);
    }, 0);

    await this.handleLogin(data);
  }

  onSocialLoginClick(provider, event, element) {
    event.preventDefault();
    event.stopPropagation();

    this.setState('isSocialLoading', true);

    try {
      let loginElement = element;
      if (!loginElement) {
        loginElement = document.querySelector(`[data-login="${provider}"]`);
      }

      if (!loginElement) {
        console.error('Cannot find social login element');
        this.notification.onError({
          error: {
            error_description:
              'Cannot log in at this time. Please reload the page and try again.',
          },
        });
        return;
      }

      const url = new URL(loginElement.href, window.location.origin);
      url.searchParams.append('login', 1);

      window.location.replace(url.href);
    } catch (ex) {
      console.error(ex);
      this.notification.onError({
        error: {
          error_description:
            'Cannot log in at this time. Please reload the page and try again.',
        },
      });
    } finally {
      this.form.reset();
    }
  }

  disableRequiredFields() {
    this.form['password'].required = false;
    this.form['email'].required = false;
  }

  enableRequiredFields() {
    this.form['password'].required = true;
    this.form['email'].required = true;
  }

  async handlePasskeyClick(event) {
    event.stopPropagation();
    event.preventDefault();

    let location;
    let publicKeyCredential;

    this.disableRequiredFields();

    if (!this.form.checkValidity()) {
      this.form.reportValidity();
      return;
    }

    setTimeout(() => {
      this.setState('isLoading', true);
    }, 0);

    const proxy = requestProxy();
    const email = this.form['email'].value;

    try {
      const data = new window.FormData();
      if (email) {
        data.set('email', email);
      }

      const challengeResponse = await proxy.post({
        path: '/auth/login/challenge',
        data,
      });
      const { options: credOptions } = challengeResponse;

      const options = structuredClone(credOptions);
      options.challenge = stringToBuffer(atob(options.challenge));

      // TODO: check "mediation: 'conditional'" on the get() call
      publicKeyCredential = await window.navigator.credentials.get({
        publicKey: options,
      });
    } catch (ex) {
      switch (ex.name) {
        case 'NotAllowedError':
          this.notification.notify({
            message: 'Passkey log in timed out or canceled by the user',
          });
          break;
        case 'SecurityError':
          this.notification.notify({
            message:
              'Log in cannot continue: the web browser is prohibiting access to the passkey',
          });
          break;
        default:
          console.error(ex);
          this.notification.onError({
            error_description_html:
              'Login cannot continue: error retrieving passkey.<br>Reload the page and try again.',
          });
          break;
      }

      this.enableRequiredFields();
      this.reset();
      return;
    }

    try {
      const data = new window.FormData();
      if (email) {
        data.set('email', email);
      }

      const { response, id } = publicKeyCredential;

      const { clientDataJSON, signature, userHandle, authenticatorData } =
        response;

      data.set('id', id);
      data.set('userHandle', bufferToString(userHandle));
      data.set('signature', btoa(bufferToString(signature)));
      data.set('authenticatorData', btoa(bufferToString(authenticatorData)));

      if (clientDataJSON) {
        const dataJSONString = bufferToString(clientDataJSON);
        const dataJSON = JSON.parse(dataJSONString);

        const { challenge, origin, crossOrigin } = dataJSON;

        data.set('clientDataJSON', btoa(dataJSONString));
        data.set('challenge', challenge);
        data.set('origin', origin);
        data.set('crossOrigin', crossOrigin);
      }

      location = '/factories';

      const loginResponse = await proxy.post({
        path: '/auth/login/passkey',
        data,
      });

      if (loginResponse.redirected) {
        location = loginResponse.headers.get('location') || '/factories';
      }

      window.location.assign(location);
    } catch (ex) {
      console.error(ex);

      this.notification.onError(ex);
      this.enableRequiredFields();
      this.reset();
    }
  }
}

export default Component;
